import React, { useMemo } from 'react';
import Image from 'next/image';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';

const ErrorContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: 20,
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 600,
  margin: 'auto',
  '@media (min-width: 1024px)': {
    paddingTop: '10%',
  },
});

const IconContainer = styled('div')({
  position: 'relative',
  width: '246px',
  height: '145px',
  marginBottom: '30px',
});

const MainTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '30px',
  lineHeight: '45px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const LinkTypography = styled('a')(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#0A7ABE',
  textDecoration: 'underline',
  cursor: 'pointer',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const SmallTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
  color: '#414046',
  [theme.breakpoints.down(570)]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

const LinkContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  marginTop: '40px',
  [theme.breakpoints.down(570)]: {
    flexDirection: 'column',
    gap: '0px',
  },
}));
export const SearchError = () => {
  const router = useRouter();
  const { query: q } = router.query;
  const searchString = q ? decodeURI(q as string) : '';
  const whatsappRoute = useMemo(() => {
    const whatsappRoute = `https://api.whatsapp.com/send?phone=56977212932&text=${encodeURI(
      `Hola! Quería pedirles ayuda para encontrar ${searchString}`,
    )}`;
    return whatsappRoute;
  }, [searchString]);
  return (
    <ErrorContainer>
      <IconContainer>
        <Image
          src="/assets/images/errorIcon.webp"
          alt="error"
          layout="fill"
          objectFit="contain"
          priority
        />
      </IconContainer>
      <MainTextContainer>
        <TextTypography>Error en la búsqueda</TextTypography>
      </MainTextContainer>

      <LinkContainer>
        <SmallTypography>Intenta de nuevo o</SmallTypography>
        <LinkTypography href={whatsappRoute} target="_blank">
          pincha aquí
        </LinkTypography>
        <SmallTypography>
          para recibir ayuda de nuestro asistente
        </SmallTypography>
      </LinkContainer>
    </ErrorContainer>
  );
};
