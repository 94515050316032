import { styled } from '@mui/system';
import {
  Badge,
  IconButton,
  Palette,
  SwipeableDrawer,
  Typography,
} from '@mui/material';

interface DrawerCartShoppingCartIconProps {
  cartQuantity: boolean;
  barChange: boolean;
}

interface DrawerCartShoppingCarteBadgeProps {
  barChange: boolean;
}

const DrawerCartShoppingCartIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'cartQuantity' && prop !== 'barChange',
})<DrawerCartShoppingCartIconProps>(({ theme, cartQuantity, barChange }) => ({
  color: '#414046',
  ...(cartQuantity
    ? barChange
      ? { color: (theme.palette as Palette).primary.main }
      : {}
    : barChange
    ? { color: (theme.palette as Palette).text.primary }
    : {}),
}));

const DrawerCartShoppingCarteBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'barChange',
})<DrawerCartShoppingCarteBadgeProps>(({ theme, barChange }) => ({
  '& .MuiBadge-anchorOriginTopRightRectangular': {
    top: -2,
    right: 3,
    border: '1px solid white',
    height: '22px !important',
    width: '22px !important',
    borderRadius: '50%',
  },
  ...(barChange
    ? {
        '& .MuiBadge-colorError': {
          color: (theme.palette as Palette).primary.main,
          backgroundColor: (theme.palette as Palette).primary.contrastText,
        },
      }
    : {
        '& .MuiBadge-colorError': {
          color: (theme.palette as Palette).primary.contrastText,
          backgroundColor: (theme.palette as Palette).primary.main,
        },
      }),
}));

const DrawerCartSwipeableDrawer = styled(SwipeableDrawer)({
  zIndex: 1300,
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: 360,
    maxWidth: '100vw',
    backgroundColor: 'rgb(247	247 247)',
  },
});

const DrawerCartAppBar = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: 0,
  width: 360,
  maxWidth: '100vw',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
  height: '64px',
  zIndex: 1,
});

const TitleTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: '#414046',
  textAlign: 'center',
  fontSize: 20,
});

const DrawerCartCloseIconButton = styled(IconButton)({
  color: '#414046',
  position: 'absolute',
  right: 10,
});

export {
  DrawerCartShoppingCartIcon,
  DrawerCartShoppingCarteBadge,
  DrawerCartSwipeableDrawer,
  DrawerCartAppBar,
  TitleTypography,
  DrawerCartCloseIconButton,
};
