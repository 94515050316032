import { styled } from '@mui/system';
import { IconButton, Palette, TextField, Typography } from '@mui/material';
interface RootProps {
  isFocus: boolean;
  isHeader: boolean;
  isVisible: boolean;
}
interface TextBoxProps {
  isFocus: boolean;
  isHeader: boolean;
  isVisible: boolean;
}
interface TextBoxDivProps {
  isFocus: boolean;
  isHeader: boolean;
}
interface SuggestionsProps {
  isFocus: boolean;
}

interface SearchFormProps {
  isFocus: boolean;
}

const Root = styled('div', {
  shouldForwardProp: (prop: string) =>
    !['isFocus', 'isHeader', 'isVisible'].includes(prop),
})<RootProps>(({ theme, isFocus, isHeader, isVisible }) => ({
  position: isHeader ? 'fixed' : 'sticky',
  top: '14px',
  height: '36px',
  width: '50%',
  left: isHeader ? '50%' : '0px',
  transform: isHeader ? 'translate(-50%, 0%)' : 'none',
  zIndex: isFocus ? 1100 : 11,
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  '& .MuiOutlinedInput-root': {
    '& fieldset': { border: 'none' },
    '&:hover fieldset': { border: 'none' },
    '&.Mui-focused fieldset': { border: 'none' },
  },
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '6px',
  },
  transition: isVisible
    ? isHeader
      ? 'opacity 0.5s ease-in'
      : 'opacity 0.5s ease-in-out'
    : 'none',
  display: 'flex',
  opacity: isVisible ? 1 : 0,
  backgroundColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    width: isFocus || !isHeader ? '100%' : '80%',
    padding: isFocus ? '0px' : '0px 14px',
    backgroundColor: isHeader && isFocus ? 'white' : 'transparent',
    top: !isHeader && isFocus ? '0px' : '14px',
    position: isHeader || (!isHeader && isFocus) ? 'fixed' : 'sticky',
    left: '0px',
    right: isFocus ? 'none' : '0px',
    transform: isFocus ? 'translate(0%, 0%)' : 'none',
    transition: 'none',
  },
}));

const SearchBoxDiv = styled('div', {
  shouldForwardProp: (prop: string) => !['isFocus', 'isHeader'].includes(prop),
})<TextBoxDivProps>(({ theme, isFocus, isHeader }) => ({
  borderRadius: isFocus && isHeader ? '22px' : isFocus ? '29px' : '32px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  pointerEvents: 'none',
  paddingTop: 0,
  border: '1px solid #EAEAEA',
  boxShadow: isHeader ? 'none' : '0px 2px 5px rgba(0, 0, 0, 0.3)',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    paddingTop: isFocus && !isHeader ? 10 : 0,
    boxShadow: 'none',
    border: 'none',
  },
  boxSizing: 'border-box',
}));

const SearchForm = styled('form', {
  shouldForwardProp: (prop: string) => !['isFocus'].includes(prop),
})<SearchFormProps>(({ theme, isFocus }) => ({
  pointerEvents: 'none',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: isFocus ? '14px' : null,
    marginBottom: isFocus ? '10px' : null,
    paddingRight: isFocus ? '14px' : null,
  },
}));

const TextBox = styled(TextField, {
  shouldForwardProp: (prop: string) =>
    !['hasInputText', 'isHeader', 'isFocus', 'isVisible'].includes(prop),
})<TextBoxProps>(({ theme, isFocus, isHeader, isVisible }) => ({
  width: '100%',
  pointerEvents: isVisible ? 'all' : 'none',
  backgroundColor: 'transparent',
  color: '#EAEAEA',
  '& .MuiOutlinedInput-input': {
    '&::placeholder': {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '24px',
      color: '#757475',
      opacity: '1!important',
    },

    paddingRight: '14px',
    paddingTop: isHeader ? '10px' : '16px',
    paddingBottom: isHeader ? '10px' : '16px',
  },
  '& .MuiOutlinedInput-root': {
    gap: '10px',
    paddingLeft: '16px',
  },
  border: 'none',
  height: isHeader ? '40px' : '56px',

  [theme.breakpoints.down('md')]: {
    border: isFocus || isHeader ? '1px solid #EAEAEA' : 'none',
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        fontSize: '14px',
      },
      paddingTop: isHeader ? '8px' : '16px',
      paddingBottom: isHeader ? '8px' : '16px',
    },
    borderRadius: '32px',
  },
}));
const EndInputAdornmentIconButton = styled(IconButton)(({ theme }) => ({
  height: '24px',
  width: '24px',
  color: (theme.palette as Palette).text.secondary,
}));
const SearchSuggestionsDiv = styled('div', {
  shouldForwardProp: (prop: string) => !['isFocus'].includes(prop),
})<SuggestionsProps>(({ theme, isFocus }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: 'none',
    borderRadius: '32px',
    pointerEvents: 'all',
  },
  [theme.breakpoints.down('md')]: {
    borderTop: 'none',
    touchAction: 'none',
  },
  pointerEvents: isFocus ? 'all' : 'none',
  width: '100%',
}));
const SearchItemsDiv = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'rgba(249, 249, 249, 1)',
  [theme.breakpoints.up('md')]: {
    '&:last-child': {
      borderBottomLeftRadius: '32px',
      borderBottomRightRadius: '32px',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    height: '100dvh',
  },
}));
const SearchSuggestionHeaderDiv = styled('div')(({ theme }) => ({
  height: '40px',
  padding: '7px 16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: (theme.palette as Palette).common.white,
  [theme.breakpoints.up('md')]: {
    borderRadius: '32px',
  },
}));
const SuggestionsTitle = styled(Typography)({
  fontSize: '15px',
});
const Divider = styled('hr')({
  margin: '0px',
  border: '0.5px solid #e0e0e0',
});
export {
  Root,
  TextBox,
  EndInputAdornmentIconButton,
  SearchSuggestionsDiv,
  SearchItemsDiv,
  SearchSuggestionHeaderDiv,
  SuggestionsTitle,
  Divider,
  SearchBoxDiv,
  SearchForm,
};
