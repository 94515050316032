/*
 * File: homeNavBar.tsx
 * Project: meki
 * File Created: Thursday, 28th July 2022 4:01:03 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Thursday, 20th July 2023 10:48:31 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, { useState, useEffect, useMemo } from 'react';
import {
  RootDiv,
  HeaderAppBar,
  HeaderToolbar,
  HeaderLeftDiv,
  PositionDivLeft,
  PositionDivRight,
  HeaderRightComponent,
  LoginButton,
  MekiLogoDiv,
} from './homeNavBar.styles';
import { DrawerMenu } from '@screens/menu';
import { MekiLogo } from '@components/icons/LogoMeki';
import { useRouter } from 'next/router';
import { useModalRouter } from '@hooks/useModalRouter';
import { getRoute, removeUndefineds } from '@utils';
import { useAuth } from '@hooks/useAuth';
import { useWindowSize } from '@hooks/useWindowSize';
import { DrawerCart } from '@screens/cart/DrawerCart';
import { useFocusSearchBox } from '@hooks/useFocusSearchBox';
import { SearchBox } from '@components/searchBox';

interface HomeNavBarProps {
  barChangeStart?: boolean | false;
  isVisible: boolean;
  setIsVisible?: (isVisible: boolean) => void;
}

export function HomeNavBar(props: HomeNavBarProps) {
  const { barChangeStart, isVisible, setIsVisible } = props;
  const size = useWindowSize();
  const desktop = useMemo(() => {
    if (!size.width) return true;
    return size.width >= 768;
  }, [size.width]);
  const [barChange, setBarChange] = useState(barChangeStart);

  useEffect(() => {
    setBarChange(barChangeStart);
  }, [barChangeStart]);

  const { appUser: user } = useAuth();
  const router = useRouter();
  const focusSearchBox = useFocusSearchBox();
  const modalRouter = useModalRouter();
  const [hideInfo, setHideInfo] = useState(false);
  const elevation = 1;

  useEffect(() => {
    if (isVisible && !desktop) {
      setHideInfo(true);
      return;
    }
    setHideInfo(false);
  }, [focusSearchBox, isVisible, desktop]);

  return (
    <RootDiv>
      <HeaderAppBar color="primary" elevation={elevation}>
        <HeaderToolbar>
          <PositionDivLeft>
            <HeaderLeftDiv>
              <DrawerMenu menuIconColorChange={barChange}></DrawerMenu>

              {!hideInfo && (
                <MekiLogoDiv>
                  <MekiLogo
                    fill={'#414046'}
                    height="24px"
                    width="auto"
                    onClick={() => {
                      void router.push(getRoute('home'));
                    }}
                  />
                </MekiLogoDiv>
              )}
            </HeaderLeftDiv>
          </PositionDivLeft>

          <SearchBox
            isHeader={true}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />

          <PositionDivRight>
            <HeaderRightComponent>
              {!hideInfo && (
                <>
                  {!user ? (
                    <LoginButton
                      size="medium"
                      onClick={() => {
                        void router.push(
                          {
                            pathname: router.pathname,
                            query: removeUndefineds({
                              ...router.query,
                              pathname: '/ingresar',
                              codigo: undefined,
                            }),
                          },
                          {
                            pathname: '/ingresar',
                            query: removeUndefineds({
                              ...modalRouter.query,
                              codigo: undefined,
                            }),
                          },
                        );
                      }}
                    >
                      {size.width >= 1110 ? 'Iniciar sesión' : 'Inicia'}
                    </LoginButton>
                  ) : (
                    <div>
                      <DrawerMenu
                        barChange={barChange}
                        isFromMenuName={`${user.name?.split(' ')[0]} ${
                          user.lastNames?.[0]?.toLocaleUpperCase() ?? ''
                        }.`}
                      ></DrawerMenu>
                    </div>
                  )}
                </>
              )}

              {(desktop || (!desktop && !focusSearchBox.isFocus)) && (
                <DrawerCart barChange={barChange} />
              )}
            </HeaderRightComponent>
          </PositionDivRight>
        </HeaderToolbar>
      </HeaderAppBar>
    </RootDiv>
  );
}
