import { styled } from '@mui/system';
import { Typography, Button, IconButton } from '@mui/material';

const DivRoot = styled('div')({
  marginTop: '64px',
  position: 'relative',
  height: 'calc(100vh - 64px)',
});

const ScrollDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
  maxHeight: 'calc(100vh - 32px - 155px)',
});

const TitleProductsTypography = styled(Typography)({
  margin: '15px 0px 15px 15px',
  fontSize: 17,
});

const EmptyDiv = styled('div')({
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '-64px',
});

const EmptyLoginButton = styled(Button)({
  margin: '0 14px',
  width: '240px',
  background: '#0A7ABE',
  marginTop: '10px',
  borderRadius: '20px',
  padding: '10px 0',
});

const EmptyButtonTextTypography = styled(Typography)({
  fontSize: 14,
  color: 'white',
  textTransform: 'none',
});

const EmptyTextTypography = styled(Typography)({
  fontSize: 18,
  padding: '0 80px',
  textAlign: 'center',
  marginTop: '10px',
});

const ShoppingCartIconButton = styled(IconButton)({
  color: '#0A7ABE',
});

export {
  TitleProductsTypography,
  ScrollDiv,
  EmptyDiv,
  EmptyLoginButton,
  EmptyTextTypography,
  ShoppingCartIconButton,
  EmptyButtonTextTypography,
  DivRoot,
};
