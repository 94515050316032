/*
 * File: meiliSearch.ts
 * Project: meki
 * File Created: Wednesday, 17th January 2024 10:50:25 am
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Wednesday, 24th January 2024 11:31:21 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2024 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { MEILISEARCH_HOST } from '@config/environment';
import { Console } from '@utils';
import { getSentry } from '@services/sentry';
import { getSearchKey } from './mekiRest';
interface MeiliSearchResponse {
  hits: {
    id: string;
    text: string;
    type: string;
    points: number;
  }[];
  query: string;
  processingTimeMs: number;
  limit: number;
  offset: number;
  estimatedTotalHits: number;
}
const cache = new Map();
export const getSuggestions = async (
  input: string,
): Promise<MeiliSearchResponse> => {
  if (cache.has(input)) {
    return cache.get(input) as MeiliSearchResponse;
  }
  try {
    const suggestedResults = await fetch(
      `${MEILISEARCH_HOST}/indexes/meki_sug/search?q=${input}&sort=points:desc&limit=10`,
      {
        headers: {
          Authorization: `Bearer ${await getSearchKey()}`,
        },
      },
    );
    const response = (await suggestedResults.json()) as MeiliSearchResponse;
    cache.set(input, response);
    return response;
  } catch (error) {
    Console.log('error suggestions', error);
    await getSentry().then((Sentry) => {
      Sentry.captureException(error);
    });
    return {
      hits: [],
      query: '',
      processingTimeMs: 0,
      limit: 0,
      offset: 0,
      estimatedTotalHits: 0,
    };
  }
};
