/*
 * File: useUpdateQuantity.ts
 * Project: meki
 * File Created: Tuesday, 13th April 2021 12:26:55 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Sunday, 30th July 2023 10:00:27 am
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { useEffect } from 'react';
import { ApolloCache, useMutation } from '@apollo/client';
import { useOrderState } from '@hooks/useOrderState';
import { OrderDetail, Product } from '@interfaces';
import { useDebouncedCallback } from '@hooks/useDebouncedCallback';
import { useDeepEffect } from '@hooks/useDeepEffect';
import {
  updateProductInOrder,
  UpdateProductInOrderMutationParams,
  UpdateProductInOrderMutationResponse,
} from '@queries/product/mutations';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  deleteProductInOrder,
  DeleteProductInOrderMutationParams,
  DeleteProductInOrderMutationResponse,
} from '@queries/cart/mutations';
import { useTagManagerEvent } from '@hooks/useTagManagerEvent';
import { Console } from '@utils';
import { useCartMenu } from '@hooks/useCartMenu';
import { usePermissions } from '@hooks/usePermissions';
import { useSegment } from '@hooks/useSegment';
interface useUpdateQuantityParams {
  product: Product;
  price?: number;
  location: string;
  index?: number;
  openRemoveModal?: () => void;
}
export function useUpdateQuantity({
  product,
  price,
  location,
  index,
}: useUpdateQuantityParams) {
  const productUuid = product?.uuid;
  // Hooks init
  const { addEcommerceEvent } = useTagManagerEvent();
  const { track } = useSegment();
  const { userCan } = usePermissions();
  const {
    startLoading: startCartMenuLoading,
    stopLoading: stopCartMenuLoading,
    stopCartMenuRef,
  } = useCartMenu();
  // App state
  const { orderUuid, order: orderFromState } = useOrderState();
  // Local State
  const [quantity, setQuantity] = useState(0);
  // Other vars
  const isLocalVendor = userCan('LOCAL_VENDOR');

  const MAX_AMOUNT = isLocalVendor
    ? product?.availability?.stock ?? 0
    : userCan('MANAGE_USER')
    ? Number.MAX_SAFE_INTEGER
    : Math.min(
        6,
        product?.discount?.price && product?.discount?.stockRestriction
          ? product?.availability.discountStock
          : ['UNAVAILABLE', 'HIDDEN', 'SOLD_OUT'].includes(
              product?.availability?.statusWithoutStock,
            )
          ? product?.availability?.stock ?? 0
          : product?.availability?.status ===
            product?.availability?.statusWithoutStock
          ? 6
          : ['AVAILABLE', 'REPRESENTATION'].includes(
              product?.availability?.statusWithoutStock,
            )
          ? 6
          : product?.availability?.stock ?? 6,
      );

  const cartOrderDetail = useMemo(() => {
    const currentCart = orderFromState;
    return (
      currentCart?.orderDetails?.find(
        (detail) => detail.product?.uuid === productUuid,
      ) ?? null
    );
  }, [orderFromState, productUuid]);

  // Mutations for Deleting Product:
  const [deleteProduct, { client: apolloClient }] = useMutation<
    DeleteProductInOrderMutationResponse,
    DeleteProductInOrderMutationParams
  >(deleteProductInOrder('productDetail'));

  const [updateQuantity] = useMutation<
    UpdateProductInOrderMutationResponse,
    UpdateProductInOrderMutationParams
  >(updateProductInOrder('addUpdateQuantity'), {
    update(cache, { data: { updateProductInOrder: detail } }) {
      updateCache(detail, cache);
    },
    optimisticResponse(params) {
      return {
        updateProductInOrder: {
          id: cartOrderDetail?.id,
          __typename: 'OrderDetail',
          quantity: params.params.quantity,
          order: orderFromState,
        },
      };
    },
  });

  // Logic

  const currentProductUuidRef = useRef(productUuid);

  useEffect(
    function cartChange() {
      const newQuantity = cartOrderDetail?.quantity;
      setQuantity(newQuantity ?? 0);
    },
    [cartOrderDetail?.quantity],
  );

  // Effects
  useDeepEffect(
    function resetQuantityIfProductChange() {
      if (currentProductUuidRef.current === productUuid) return;
      currentProductUuidRef.current = productUuid;
      setQuantity(cartOrderDetail ? cartOrderDetail.quantity : 0);
    },
    [orderFromState?.orderDetails, productUuid],
  );

  // Logic
  const updateCache = useCallback(
    (
      orderDetail: UpdateProductInOrderMutationResponse['updateProductInOrder'],
      cache: ApolloCache<UpdateProductInOrderMutationResponse> = apolloClient.cache as ApolloCache<UpdateProductInOrderMutationResponse>,
    ) => {
      cache.modify({
        id: cache.identify(orderDetail.order),
        fields: {
          orderDetails(currentOrderDetails: OrderDetail[], { readField }) {
            return currentOrderDetails.map((detail) => {
              if (readField('id', detail) !== orderDetail?.id) return detail;
              return {
                ...detail,
                quantity: orderDetail.quantity,
              };
            });
          },
        },
      });
    },
    [apolloClient.cache],
  );
  const handleUpdateQuantity = useCallback(
    (newQuantity: number) => {
      if (!cartOrderDetail) {
        return -1;
      }
      if (newQuantity <= 0) {
        void deleteProduct({
          variables: {
            params: {
              orderUuid: orderUuid,
              productUuid: cartOrderDetail.product?.uuid,
            },
          },
        }).then(() => {
          setQuantity(1);
        });

        return;
      }
      void updateQuantity({
        variables: {
          params: {
            orderUuid,
            productUuid,
            quantity: newQuantity,
          },
        },
      });
    },
    [cartOrderDetail, deleteProduct, orderUuid, productUuid, updateQuantity],
  );
  const debouncedUpdateQuantity = useDebouncedCallback(
    handleUpdateQuantity,
    1000,
  );

  const addQuantity = useCallback(
    (amountToAdd: number = 1) => {
      startCartMenuLoading();
      const validation = isLocalVendor
        ? quantity < MAX_AMOUNT &&
          product?.availability?.status === 'AVAILABLE_24_HRS'
        : quantity <= MAX_AMOUNT;

      if (validation) {
        try {
          updateCache({ ...cartOrderDetail, quantity: quantity + amountToAdd });
          setQuantity(quantity + amountToAdd);
        } catch (error) {
          clearTimeout(stopCartMenuRef.current);
          stopCartMenuRef.current = setTimeout(() => {
            stopCartMenuLoading();
          }, 2000);
        }
        addEcommerceEvent('add_to_cart', {
          items: [
            {
              item_brand: product?.laboratory,
              item_name: product?.name,
              item_id: String(product?.id),
              price: product?.price,
              quantity: amountToAdd,
              prescription_type: product?.prescriptionType,
              availability: product?.availability?.status,
            },
          ],
        });

        void track('product add to cart', {
          id: product?.id,
          name: product?.name,
          brand: product?.makerBrand ?? product?.laboratory,
          prescriptionType: product?.prescriptionType ?? 'null',
          price: price ?? product?.price,
          quantity: quantity + amountToAdd,
          availability: product?.availability?.status,
          location,
          index,
        });
        Console.log({ msg: '[useUpdateQuantity] addQuantity', location });
        void debouncedUpdateQuantity(quantity + amountToAdd);
      }
      clearTimeout(stopCartMenuRef.current);
      stopCartMenuRef.current = setTimeout(() => {
        stopCartMenuLoading();
      }, 2000);
    },
    [
      isLocalVendor,
      quantity,
      MAX_AMOUNT,
      product?.availability?.status,
      product?.laboratory,
      product?.name,
      product?.id,
      product?.price,
      product?.prescriptionType,
      product?.makerBrand,
      startCartMenuLoading,
      stopCartMenuRef,
      addEcommerceEvent,
      track,
      price,
      location,
      index,
      debouncedUpdateQuantity,
      updateCache,
      cartOrderDetail,
      stopCartMenuLoading,
    ],
  );

  const subQuantity = useCallback(() => {
    startCartMenuLoading();
    const validation = quantity > 0;
    if (validation) {
      try {
        updateCache({ ...cartOrderDetail, quantity: quantity - 1 });
        setQuantity(quantity - 1);
        void debouncedUpdateQuantity(Math.max(quantity - 1, 0));
      } catch (error) {
        stopCartMenuRef.current = setTimeout(() => {
          stopCartMenuLoading();
        }, 2000);
      }
      addEcommerceEvent('remove_from_cart', {
        items: [
          {
            item_brand: product?.laboratory,
            item_name: product?.name,
            item_id: String(product?.id),
            price: product?.price,
            quantity: 1,
          },
        ],
      });

      void track('product remove from cart', {
        id: product?.id,
        name: product?.name,
        brand: product?.makerBrand ?? product?.laboratory,
        prescriptionType: product?.prescriptionType ?? 'null',
        price: product?.price ?? price,
        quantity: quantity - 1,
        availability: product?.availability?.status,
        location,
        index,
      });
      Console.log({ msg: '[useUpdateQuantity] subQuantity', location });
    }
    clearTimeout(stopCartMenuRef.current);
    stopCartMenuRef.current = setTimeout(() => {
      stopCartMenuLoading();
    }, 2000);
  }, [
    quantity,
    product?.availability?.status,
    product?.laboratory,
    product?.name,
    product?.id,
    product?.price,
    product?.makerBrand,
    product?.prescriptionType,
    startCartMenuLoading,
    stopCartMenuRef,
    addEcommerceEvent,
    track,
    price,
    location,
    index,
    updateCache,
    cartOrderDetail,
    debouncedUpdateQuantity,
    stopCartMenuLoading,
  ]);

  return { addQuantity, subQuantity, quantity, setQuantity, MAX_AMOUNT };
}
