import { useRouter } from 'next/router';
import React, { useCallback, useContext, useMemo, useState } from 'react';

interface OpenModalContextValue {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

const OpenModalContext = React.createContext<OpenModalContextValue>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const useOpenModal = () => {
  const value = useContext(OpenModalContext);
  return value;
};

interface OpenModalProviderProps {
  children: React.ReactNode;
}

export const OpenModalProvider = ({ children }: OpenModalProviderProps) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const openModalFunction = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModalFunction = useCallback(() => {
    if (open)
      void router.push(
        {
          pathname: router.pathname,
          query: Object.entries(router.query).reduce((query, [key, val]) => {
            if (router.pathname.includes(key) || ['page', 'q'].includes(key))
              return { ...query, [key]: val };
            return query;
          }, {}),
        },
        null,
        { scroll: false },
      );
    setOpen(false);
  }, [router, open]);
  const value = useMemo(() => {
    return {
      isModalOpen: open,
      openModal: openModalFunction,
      closeModal: closeModalFunction,
    };
  }, [open, openModalFunction, closeModalFunction]);
  return (
    <OpenModalContext.Provider value={value}>
      {children}
    </OpenModalContext.Provider>
  );
};
