import React, { memo, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
  ProductQuery,
  ProductQueryParams,
  ProductQueryResponse,
} from '@queries/products/queries';
import { useRouter } from 'next/router';
import { useSegment } from '@hooks/useSegment';
import GlobalStyles from '@mui/material/GlobalStyles';
import { SearchContentContainer } from '@components/SearchContentContainer';
import { useMekiState } from '@hooks/useMekiState';
import { Products } from '@components/Products';
import { SearchNotFound } from './searchNotFound';
import { useBottomReach } from '@hooks/useBottomReach';
import { SearchError } from './searchError';
import { getSentry } from '@services/sentry';
import { styled } from '@mui/material';
import { getSearchTitleBox } from './helpers/getSearchTitle';
import { AllPrescriptionTypeCheckModal } from './allPrescriptionTypeCheckModal';
import { Loader } from '@components/Loader';
import { HomeNavBar } from '@screens/home/components/homeNavBar';
const backgroundGlobalStyle = (
  <GlobalStyles
    styles={{
      body: {
        backgroundColor: '#f9f9f9 !important',
      },
    }}
  />
);

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '10px 10px 0 10px',
  [theme.breakpoints.down(320)]: {
    padding: '10px 0 0 0',
  },
}));
const ProductListContainer = styled('div')({
  width: '100%',
  height: 'fit-content',
  maxWidth: 1200,
  margin: 'auto',
});
const ProductList = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 320,
  [theme.breakpoints.up(320)]: {
    maxWidth: 'calc(100vw - 20px)',
  },
}));

function SearchResults() {
  // Hooks init:
  const router = useRouter();
  const { track } = useSegment();

  const { setLastSearch } = useMekiState();
  // Local States
  const { q } = router.query;
  const searchString = q ? decodeURI(q as string) : '';

  // Queries
  const {
    data: pageData,
    loading,
    fetchMore,
    error,
  } = useQuery<ProductQueryResponse, ProductQueryParams>(
    ProductQuery('SearchResults'),
    {
      variables: {
        params: { query: searchString, pageNumber: 1 },
      },
      fetchPolicy: 'cache-first',
      skip: !searchString,
    },
  );
  const titleCarrusel =
    pageData?.products?.perfectMatchProducts?.length > 1
      ? `Resultados ${searchString}`
      : 'Resultados';

  const titleProductBox = getSearchTitleBox(searchString, pageData, loading);
  const productsNodes = pageData?.products?.nodes.filter(
    (product) => product.availability.status !== 'HIDDEN',
  );
  const allSoldOutResults = useMemo(() => {
    return productsNodes?.every((product) =>
      ['UNAVAILABLE', 'HIDDEN', 'SOLD_OUT'].includes(
        product.availability.status,
      ),
    );
  }, [productsNodes]);

  const allPrescriptionTypeCheck = useMemo(() => {
    const products = pageData?.products;
    const allProducts = [
      ...(products?.perfectMatchProducts ?? []),
      ...(productsNodes ?? []),
    ];
    if (allProducts?.length === 0) return false;
    return allProducts?.every(
      (product) => product.prescriptionType === 'CHECK',
    );
  }, [pageData?.products, productsNodes]);

  const handleFetchMore = useMemo(() => {
    return async () => {
      if (
        pageData?.products?.pageInfo.totalPages <=
        (pageData?.products.pageNumber ?? 1)
      ) {
        return;
      }
      await fetchMore({
        variables: {
          params: {
            query: searchString,
            pageNumber: (pageData?.products.pageNumber ?? 1) + 1,
          },
        },
      });
    };
  }, [
    fetchMore,
    pageData?.products?.pageInfo?.totalPages,
    pageData?.products?.pageNumber,
    searchString,
  ]);
  const { loading: loadingMore } = useBottomReach(handleFetchMore);
  useEffect(
    function trackSearch() {
      if (loading || pageData?.products?.query !== searchString) return null;
      void track('product search', {
        results: pageData?.products?.totalCount ?? 0,
        query: searchString,
      });
    },
    [
      loading,
      pageData?.products?.totalCount,
      searchString,
      track,
      pageData?.products?.query,
    ],
  );

  useEffect(
    function trackLastSearch() {
      setLastSearch(
        new URLSearchParams(
          router.query as unknown as Record<string, string>,
        ).toString(),
      );
    },
    [router.query, setLastSearch],
  );
  useEffect(
    function reportSearchError() {
      if (error) {
        void getSentry().then((Sentry) => {
          Sentry.captureException(error, {
            extra: { searchString },
          });
        });
      }
    },
    [error, searchString],
  );
  return (
    <>
      {backgroundGlobalStyle}
      <HomeNavBar isVisible={true} />
      <SearchContentContainer>
        {error ? (
          error.message === 'QUERY_MIN_LENGTH' ? (
            <SearchNotFound />
          ) : (
            <SearchError />
          )
        ) : productsNodes?.length > 0 ||
          pageData?.products?.perfectMatchProducts?.length > 0 ||
          loading ||
          loadingMore ? (
          <>
            {loading && <Loader />}
            {pageData?.products?.perfectMatchProducts?.length > 0 &&
              (allSoldOutResults || productsNodes?.length == 0 ? (
                <Products
                  data={pageData?.products?.perfectMatchProducts.map(
                    (product) => ({
                      product,
                    }),
                  )}
                  loading={loading || loadingMore}
                  title={titleCarrusel}
                  location="search results"
                />
              ) : (
                <Container>
                  <ProductListContainer>
                    <ProductList>
                      <Products.Carousel
                        data={pageData?.products?.perfectMatchProducts.map(
                          (product) => ({
                            product,
                          }),
                        )}
                        totalCount={
                          pageData?.products?.perfectMatchProducts?.length
                        }
                        loading={loading}
                        title={titleCarrusel}
                        location={'search results'}
                      />
                    </ProductList>
                  </ProductListContainer>
                </Container>
              ))}
            {productsNodes?.length > 0 &&
              (pageData?.products?.perfectMatchProducts?.length > 0 ? (
                !allSoldOutResults && (
                  <Products
                    data={
                      productsNodes?.map((product) => ({
                        product,
                      })) ?? []
                    }
                    loading={loading || loadingMore}
                    title={titleProductBox}
                    location="search results"
                  />
                )
              ) : (
                <Products
                  data={
                    productsNodes?.map((product) => ({
                      product,
                    })) ?? []
                  }
                  loading={loading || loadingMore}
                  title={titleProductBox}
                  location="search results"
                />
              ))}
          </>
        ) : (
          <SearchNotFound />
        )}
      </SearchContentContainer>
      {allPrescriptionTypeCheck && pageData?.products && (
        <AllPrescriptionTypeCheckModal />
      )}
    </>
  );
}

const MemoizedSearchResults = memo(SearchResults);
export { MemoizedSearchResults as SearchResults };
