import { API_GATEWAY_URI, MEILISEARCH_KEY } from '@config/environment';
import axios from 'axios';

export const mekiRestApi = axios.create({
  baseURL: API_GATEWAY_URI,
});

interface GetSearchKeyResponse {
  key: string;
}
let searchKey: string | null = null;
export const getSearchKey = async (): Promise<string> => {
  if (!searchKey && typeof window !== 'undefined') {
    try {
      const response = await mekiRestApi.get<GetSearchKeyResponse>(
        '/search/key',
      );
      searchKey = response.data.key;
    } catch (error) {
      searchKey = MEILISEARCH_KEY;
      console.error('Error getting search key');
      console.error(error);
    }
  }
  return searchKey;
};
export const resetSearchKey = async (): Promise<string> => {
  searchKey = null;
  return getSearchKey();
};
// eslint-disable-next-line no-console
void getSearchKey().catch(console.error);
