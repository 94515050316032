/*
 * File: homeNavBa
 * Project: meki
 * File Created: Thursday, 28th July 2022 4:01:26 pm
 * Author: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Last Modified: Wednesday, 18th January 2023 12:23:58 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { styled } from '@mui/system';
import { AppBar, Toolbar, Button, IconButton, Palette } from '@mui/material';

const RootDiv = styled('div')({
  flexGrow: 1,
  '& .MuiButton-root': {
    textTransform: 'None',
  },
  position: 'sticky',
  top: 0,
  zIndex: 1100,
});
const HeaderAppBar = styled(AppBar)(({ theme, elevation }) => ({
  top: 0,
  bottom: 'auto',
  height: 64,
  background: (theme.palette as Palette).common.white,
  boxShadow: elevation ? '0px 1px 10px rgb(0 0 0 / 12%)' : 'none',
  transition: 'background-color 0.4s',
  backdropFilter: 'blur(5px)',
  '@media (min-width: 800px)': {
    height: 'inherit',
  },
  position: 'relative',
}));
const HeaderToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
  minHeight: 64,
  backgroundColor: 'transparent',
  paddingRight: 0,
  paddingLeft: '12px',
});
const PositionDivLeft = styled('div')(({ theme }) => ({
  flex: 0,
  position: 'absolute',
  left: 20,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    zIndex: 0,
    left: 0,
    position: 'unset',
  },
}));
const PositionDivRight = styled('div')(({ theme }) => ({
  flex: 0,
  position: 'absolute',
  right: 12,
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    zIndex: 3000,
    right: 0,
    position: 'unset',
  },
}));
const HeaderLeftDiv = styled('div')({
  zIndex: 0,
  display: 'flex',
  alignItems: 'center',
});
const BackIconButton = styled(IconButton)(() => ({
  color: '#004C8C',
}));
const HeaderRightComponent = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const MekiLogoDiv = styled('div')({
  cursor: 'pointer',
  marginLeft: 9,
  display: 'flex',
});
const LoginButton = styled(Button)(() => ({
  color: '#414046',
  borderColor: '#414046',
  height: 19,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '1px',
  maxWidth: 180,
  textDecoration: 'underline',
  '@media (max-width: 767px)': {
    fontSize: 12,
  },
}));

export {
  RootDiv,
  HeaderAppBar,
  HeaderToolbar,
  HeaderLeftDiv,
  PositionDivLeft,
  PositionDivRight,
  BackIconButton,
  HeaderRightComponent,
  LoginButton,
  MekiLogoDiv,
};
