/*
 * File: searchBackdrop.tsx
 * Project: meki
 * File Created: Tuesday, 18th May 2021 10:07:59 am
 * Author: Esperanza Horn (esperanza@inventures.cl)
 * -----
 * Last Modified: Friday, 2nd February 2024 10:19:26 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2020 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React from 'react';
import { styled } from '@mui/system';
import { Palette } from '@mui/material';

interface BackdropProps {
  handleToggleClick: (tag?: unknown) => void;
  trasparent?: boolean;
  isFocus: boolean;
}
interface BackdropDivProps {
  trasparent?: boolean;
  isFocus: boolean;
}
const BackdropDiv = styled('div', {
  shouldForwardProp: (prop) =>
    !['trasparent', 'isFocus'].includes(prop as string),
})<BackdropDivProps>(({ theme, trasparent, isFocus }) => ({
  width: '100vw',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100dvh',
  position: 'fixed',
  background: trasparent ? '' : ' rgba(0,0,0,0.1)',
  zIndex: 995,
  top: 0,
  right: 0,
  left: 0,
  color: (theme.palette as Palette).primary.dark,
  [theme.breakpoints.down('md')]: {
    visibility: 'hidden',
  },
  [theme.breakpoints.up('md')]: {
    visibility: isFocus ? 'visible' : 'hidden',
  },
}));
export function SearchBackdrop({
  handleToggleClick,
  trasparent,
  isFocus,
}: BackdropProps) {
  // Hooks init:
  return (
    <BackdropDiv
      onClick={handleToggleClick}
      trasparent={trasparent}
      isFocus={isFocus}
    />
  );
}
