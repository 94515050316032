/*
 * File: index.tsx
 * Project: meki
 * File Created: Tuesday, 27th December 2022 12:08:04 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Thursday, 14th December 2023 12:40:29 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { ProductCard } from './ProductCard';
import { Product } from '@interfaces';
import { useIntersectionObserver } from '@hooks/useIntersection';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Image from 'next/image';
import {
  ProductList,
  List,
  TitleContainer,
  Title,
  Subtitle,
  SeeAllContainer,
  LinkColumn,
  IconContainer,
  ForwardButton,
  BackButton,
  Column,
  Row,
} from './Carousel.styles';

export interface ProductsTypes {
  data: { product: Product; orderId?: number }[];
  loading: boolean;
  title: string;
  totalCount?: number;
  subtitle?: string;
  icon?: string;
  action?: ReactNode;
  location?: string;
  quantityChangeCallback?: (action: 'add' | 'remove') => void; // callback function for when adding a product to cart
}

export function Carousel(props: ProductsTypes) {
  const {
    data,
    totalCount,
    title,
    subtitle,
    icon,
    action,
    location,
    quantityChangeCallback,
  } = props;
  const firstRef = useRef<HTMLDivElement | null>(null);
  const lastRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const leftNavigation = useIntersectionObserver(firstRef, { threshold: 1 });
  const rightNavigation = useIntersectionObserver(lastRef, {
    threshold: 0.99,
  });

  const leftNavigationisVisible = useMemo(() => {
    if (!leftNavigation) return;
    return !leftNavigation.isIntersecting;
  }, [leftNavigation]);

  const rightNavigationisVisible = useMemo(() => {
    if (!rightNavigation) return;
    return !rightNavigation.isIntersecting;
  }, [rightNavigation]);
  const forward = useCallback(() => {
    listRef.current.scrollTo({
      left: listRef.current.scrollLeft + listRef.current.clientWidth / 3,
      behavior: 'smooth',
    });
  }, []);
  const backward = useCallback(() => {
    listRef.current.scrollTo({
      left: listRef.current.scrollLeft - listRef.current.clientWidth / 3,
      behavior: 'smooth',
    });
  }, []);

  const carouselComponents = data?.reduce(
    (acc, e) => {
      const product = e.product;
      return {
        activePriciple: acc.activePriciple || !!product.activePrinciple,
        concentrationLab:
          acc.concentrationLab ||
          !!product.concentration ||
          !!product.laboratory,
        quantity: acc.quantity || !!product.quantity,
        discount:
          acc.discount ||
          (product.discount?.price &&
            !['SOLD_OUT', 'HIDDEN', 'UNAVAILABLE'].includes(
              product.availability?.status,
            )),
        averagePrice:
          acc.averagePrice ||
          (/comprimido|cápsula/i.test(product.unit) &&
            (!!product.discount?.price || !!product.price)),
      };
    },
    {
      activePriciple: false,
      concentrationLab: false,
      quantity: false,
      discount: false,
      averagePrice: false,
    },
  );

  if (data?.length == 0) return null;
  return (
    <ProductList>
      {icon ? (
        <TitleContainer>
          <Row>
            <SeeAllContainer>
              {totalCount == 1 ? (
                <Column>
                  <Row>
                    <IconContainer>
                      <Image
                        src={icon}
                        alt="icon"
                        layout="fill"
                        objectFit="contain"
                      />
                    </IconContainer>
                    <Title variant="h2">{title}</Title>
                  </Row>
                  {subtitle && <Subtitle addMarginTop>{subtitle}</Subtitle>}
                </Column>
              ) : (
                <Row>
                  <IconContainer>
                    <Image
                      src={icon}
                      alt="icon"
                      layout="fill"
                      objectFit="contain"
                    />
                  </IconContainer>
                  <Column>
                    <Title variant="h2">{title}</Title>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  </Column>
                </Row>
              )}
              <LinkColumn>{action}</LinkColumn>
            </SeeAllContainer>
          </Row>
        </TitleContainer>
      ) : (
        <TitleContainer>
          <SeeAllContainer>
            <Title variant="h2">{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <LinkColumn>{action}</LinkColumn>
          </SeeAllContainer>
        </TitleContainer>
      )}
      <List ref={listRef}>
        {!data &&
          Array.from({ length: 7 }).map((e, i) => {
            return <ProductCard.Skeleton listLength={7} key={i} />;
          })}

        {data?.map((element, i, array) => {
          const product = element?.product;
          const card = product ? (
            <ProductCard
              key={product?.id}
              product={product}
              index={i}
              listLength={array.length}
              inCarousel={true}
              location={location}
              quantityChangeCallback={quantityChangeCallback}
              carouselComponents={carouselComponents}
              ref={i == array.length - 1 ? lastRef : i == 0 ? firstRef : null}
            />
          ) : null;
          if (i === 0)
            return (
              <Fragment key={product?.id}>
                {leftNavigationisVisible && (
                  <BackButton onClick={backward}>
                    <ArrowForwardIosRoundedIcon color="primary" />
                  </BackButton>
                )}
                {card}
              </Fragment>
            );
          if (i === array.length - 1)
            return (
              <Fragment key={product?.id}>
                {rightNavigationisVisible && (
                  <ForwardButton onClick={forward}>
                    <ArrowForwardIosRoundedIcon color="primary" />
                  </ForwardButton>
                )}
                {card}
              </Fragment>
            );

          return card;
        })}
      </List>
    </ProductList>
  );
}
