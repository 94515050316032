/*
 * File: summary.ts
 * Project: meki
 * File Created: Thursday, 11th November 2021 10:55:35 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 16th June 2023 5:33:36 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { gql } from '@apollo/client';
import { ResolverParams } from '@queries/queries.interfaces';
import { Order, User, Terms } from '@interfaces';

export const getFinalSummary = () => gql`
  query OrdersByUuid($params: GetOrdersByUuidParams!) {
    ordersByUuid(params: $params) {
      id
      uuid
      orderGroupId
      deliveryType
      deliveryCost
      totalCost
      mekiCreditsUsed
      onDemand
      orderInsurance {
        id
        claimId
        totalReimbursement
        totalDeductible
        totalCopay
        reimbursedProducts {
          sku
          unitReimbursement
          unitCopay
          unitDeductible
        }
      }
      metadata {
        fintocPaymentIntentId
        cancelledByFintoc
        installments
        insuranceInfo {
          claimId
          totalReimbursement
          totalDeductible
        }
      }
      payments {
        id
        status
        amount
        paymentMethodType
      }
      address {
        id
        uuid
        apartment
        commune
        street
        streetNumber
        details
        name
      }
      paymentMethod {
        id
        uuid
        type
        data
      }
      status
      orderGroupId
      paidAt
      deliveryDate
      isFirstOrderReferrals
      orderDetails {
        id
        quantity
        price
        insurance {
          unitReimbursement
          unitDeductible
          unitCopay
        }
        listPrice
        type
        product {
          id
          name
          concentration
          quantity
          unit
          format
          imagesUrl
          display
          price
          prescriptionType
          metadata {
            refrigerated
          }
        }
      }
    }
    currentUserInfo {
      id
      acceptedTerms
    }
    currentTerms {
      id
      uuid
      url
    }
  }
`;

export type GetFinalSummaryQueryResponse = {
  ordersByUuid: Order[];
  currentUserInfo: Pick<User, 'id' | 'acceptedTerms'>;
  currentTerms: Terms;
};

export type GetFinalSummaryQueryParams = ResolverParams<{
  uuid: string;
  status: string[];
}>;
